import { Box, Typography } from '@mui/material';
import OutlinedButton from "../../../../components/buttons/OutlinedButton";
import MasterCard from "../../../../assets/images/Mastercard.svg";
import Visa from "@/assets/images/VISA.svg";
import Discover from "@/assets/images/Discover.svg";
import Amex from "@/assets/images/AMEX.svg";
import Jcb from "@/assets/images/jcb.svg";
import Diners from "@/assets/images/diners_club.svg";
import Maestro from "@/assets/images/maestro.svg";

const PaymentMethodCard = (props) => {
  const { t, ProfileData } = props;
  const formatExpiry = (expiry) => {
    if (!expiry) return "-";
    const expiryStr = expiry.toString().padStart(4, "0");
    const month = expiryStr.slice(0, 2);
    const year = "20" + expiryStr.slice(2); // assuming it's 2000s
    return `${month}/${year}`;
  };

  const formatCardNumber = (card) => {
    if (!card || card.length < 5) return ""; // basic safety

    const firstDigit = card[0];
    const lastFour = card.slice(-4).toLowerCase(); // get last 4 characters
    const maskedPart = `${firstDigit}*** **** ****`;

    // Show real last 4 digits only if they are not "xxxx"
    const showLastFour = lastFour !== "xxxx" ? lastFour : "****";

    return `${maskedPart} ${showLastFour}`;
  };
  return (
    <Box sx={BoxStyle}>
      <Box sx={CardHeaderStyle}>
        <Typography variant='h5' sx={{ fontWeight: 500 }} >
          {t("Dashboard.Current_Payment_Method")}
        </Typography>
        {ProfileData?.card && (
          <OutlinedButton
            text={t("Dashboard.change")}
          />
        )}

      </Box>
      <Box sx={CardDetailContainerStyle}>
        <Box sx={InnerContainerStyle}>
          {ProfileData?.card ? (
            <Box sx={CardDetailStyle}>
              <img
                src={ProfileData?.card?.card_type.toLowerCase() === "diner's club" ? Diners : ProfileData?.card?.card_type.toLowerCase() === "visa" ? Visa : ProfileData?.card?.card_type.toLowerCase() === "discover" ? Discover : ProfileData?.card?.card_type.toLowerCase() === "jcb" ? Jcb : ProfileData?.card?.card_type.toLowerCase() === "american express" ? Amex : ProfileData?.card?.card_type.toLowerCase() === "maestro" ? Maestro : MasterCard}
                style={{ width: "36px", height: "24px" }}
              />
              <Box sx={CardTextStyle}>
                <Typography variant='h5' sx={{ fontWeight: 500 }} >
                  {ProfileData?.card?.card_type}
                </Typography>
                <Typography variant='h5' sx={{ fontWeight: 500, fontFamily: 'monospace', letterSpacing: '0.05em' }} >

                  {formatCardNumber(ProfileData?.card?.card)}

                </Typography>
                <Typography variant='caption' sx={{ color: "#667085", fontWeight: "400" }}>
                  Expiry on {formatExpiry(ProfileData?.card?.cc_expiry)}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Typography variant='subtitle2'>
              No payment information added.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default PaymentMethodCard;

const BoxStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "column"
}

const CardHeaderStyle = {
  width: "100%",
  display: "flex",
  height: "56px",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "8px 16px",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  border: "1px solid #DFE4EA",
  backgroundColor: "#F3F4F6",
}

const CardDetailContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px",
  border: "1px solid #DFE4EA",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
}

const InnerContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",

}

const CardDetailStyle = {
  display: "flex",
  gap: "16px",
}

const CardTextStyle = {
  display: "flex",
  gap: "8px",
  flexDirection: "column",
}

// const BillingStyle = {
//   display: "flex",
//   alignItems: "center",
//   gap: "4px",
// }