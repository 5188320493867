import { DialogContent, Typography } from "@mui/material";
import ListComp from "./ListComp";

const TermAndCondation = () => {
  return (
    <DialogContent>
      <Typography variant="h3" sx={{ mb: "12px" }}>
        Introduction
      </Typography>
      <Typography variant="body1" paragraph>
        These terms constitute a legal agreement between you and icōd.ai Labs
        Incorporated ("icōd.ai," "icōd.ai" "we," or "us"). Your use of icōd.ai
        (the "Site") and the services made available on the Site ("Services") is
        subject to these Terms of Service (these "Terms"). By using the Site and
        any of our Services, you:
      </Typography>
      <ListComp
        arr={[
          "Acknowledge that you have read and understood these Terms",
          "Agree to be bound by these Terms",
          "Agree to our Privacy Policy (icōd.ai/privacy)",
          "Commit to comply with all applicable laws and regulations",
        ]}
      />
      <Typography variant="h3" sx={{ mb: "12px" }}>
        License to use our services
      </Typography>
      <Typography variant="body1" paragraph>
        Subject to these Terms, we grant you a limited, personal, non-exclusive,
        non-transferable license to use our Services. This license includes the
        right to use our Services for both personal and commercial purposes,
        subject to your subscription plan and these Terms.
      </Typography>
      <Typography variant="h3" sx={{ mb: "12px" }}>
        Intellectual property rights
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Our rights
      </Typography>
      <Typography variant="body1" paragraph>
        icōd.ai Labs Incorporated owns and retains all rights, title, and
        interest in and to:
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Brand elements
      </Typography>
      <Typography variant="body1" paragraph></Typography>
      <ListComp
        arr={[
          `The "icōd.ai" name and brand`,
          `The "icōd.ai." domain name and all related domains and variants`,
          `The "GPT Engineer" trademark and brand and variants`,
          "All icōd.ai logos, designs, and visual elements",
          "All marketing materials and website content",
        ]}
      />
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Platform elements
      </Typography>
      <Typography variant="body1" paragraph></Typography>
      <ListComp
        arr={[
          `The Site architecture and design`,
          `All platform features and functionalities`,
          `Our proprietary algorithms and systems`,
          "The user interface and experience design",
          "All documentation and supporting materials",
        ]}
      />
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Website content
      </Typography>
      <Typography variant="body1" paragraph>
        All text, graphics, and media on icōd.ai. Blog posts, documentation, and
        guides Marketing materials and promotional content Interface elements
        and design components These elements are protected by copyright,
        trademark, trade dress, patent laws, and other intellectual property
        rights.
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Clear distinction of rights
      </Typography>
      <Typography variant="body1" paragraph>
        To be explicitly clear, while icōd.ai owns all rights to the platform
        and brand elements described above, this ownership is entirely separate
        from and does not extend to:
      </Typography>
      <ListComp
        arr={[
          `User-generated content`,
          `Code generated using our platform`,
          `Applications built with our tools`,
          "Custom configurations and implementations",
          "Modified or derivative works created from generated code",
          "Your own code commits and changes made outside of icōd.ai but committed or visible in any way",
        ]}
      />
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        User rights
      </Typography>
      <Typography variant="body1" paragraph>
        icōd.ai makes no claim to ownership of:
      </Typography>
      <ListComp
        arr={[
          "All code generated using our Services",
          "Applications and solutions built using our platform",
          "Custom implementations and modifications",
          "Derivative works based on generated code",
          "Your business logic and application structure",
        ]}
      />
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Usage rights
      </Typography>
      <Typography variant="body1" paragraph>
        You may freely:
        <ListComp
          arr={[
            "Use generated code commercially",
            "Modify and adapt generated code",
            "Distribute generated code in any form",
            "Sell applications built using our platform",
            "Open source your implementations",
          ]}
        />
        To be explicitly clear:
        <ListComp
          arr={[
            "icōd.ai places no restrictions on your use of generated code",
            "You are free to use, modify, and distribute generated code without attribution requirements",
            "No licenses or royalties are owed by icōd.ai users to icōd.ai for using generated code",
            "Your implementations and modifications are yours to control",
            "You can combine generated code with other sources without needing approval from icōd.ai",
          ]}
        />
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Data usage, training, and learning
      </Typography>
      <Typography variant="body1" paragraph>
        We collect and process data from your use of the Services to improve our
        platform, provide support, and enhance user experience. While our
        Privacy policy contains detailed information about data collection and
        processing, this section outlines how we use your data within our
        Services.
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Service improvement and training
      </Typography>
      <Typography variant="body1" paragraph>
        Your use of our platform contributes to its continuous improvement. We
        analyze generated code, prompts, and usage patterns to enhance our AI
        systems and improve code generation accuracy. This analysis helps us
        understand how developers interact with our platform, enabling us to
        optimize performance and reliability while identifying areas for
        enhancement. We specifically use this data to:
        <ListComp
          arr={[
            "Train and refine our AI systems, improving their ability to understand developer requirements and generate appropriate code",
            "Enhance the quality and accuracy of code generation through pattern analysis and learning",
            "Optimize platform performance based on real-world usage patterns",
            "Identify and resolve technical issues through systematic analysis",
            "Develop new features and capabilities based on user needs and behavior",
          ]}
        />
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Support and operational uses
      </Typography>
      <Typography variant="body1" paragraph>
        We utilize collected data to provide comprehensive support and maintain
        optimal platform operations. This includes monitoring system
        performance, analyzing error patterns, and generating internal reports
        that help us maintain and improve service quality. Our support team uses
        this information to:
        <ListComp
          arr={[
            "Provide more effective technical assistance by understanding common issues and usage patterns",
            "Monitor and maintain platform stability and performance",
            "Generate insights that help improve our documentation and user guides",
            "Create aggregate usage statistics for service optimization",
            "Develop internal analytics that guide platform improvements",
          ]}
        />
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Enterprise plan considerations
      </Typography>
      <Typography variant="body1" paragraph>
        Enterprise customers receive enhanced data protection and control. For
        these users:
        <ListComp
          arr={[
            "We explicitly waive our right to use their data for training purposes",
            "Custom data handling agreements are available",
            "No sharing with third parties occurs without explicit consent",
          ]}
        />
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Data retention and management
      </Typography>
      <Typography variant="body1" paragraph>
        We maintain clear policies regarding data retention and management:
        <ListComp
          arr={[
            "Data is retained only as long as necessary for the purposes described above",
            "You may request data deletion subject to our retention policies and technical feasibility",
            "Some data may be retained for compliance purposes",
          ]}
        />
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Our commitment to responsible data usage
      </Typography>
      <Typography variant="body1" paragraph>
        We are committed to responsible data usage practices that balance
        platform improvement with user privacy and trust. This includes:
        <ListComp
          arr={[
            "Implementing strict data access controls",
            "Regular security audits and compliance checks",
            "Employee training on data handling",
            "Transparent data usage practices",
            "Regular policy reviews and updates",
          ]}
        />
        For complete details about data collection, processing, and your privacy
        rights, please refer to our Privacy policy at icōd.ai. /privacy.
      </Typography>
      <Typography variant="h3" sx={{ mb: "12px" }}>
        User accounts and content
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Account creation and management
      </Typography>
      <Typography variant="body1" paragraph>
        <ListComp
          arr={[
            "You may create an account to use our Services",
            "You are responsible for maintaining account security",
            "Currently, account deletion must be requested via support",
            "We reserve the right to terminate accounts that violate these Terms",
          ]}
        />
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        User content
      </Typography>
      <Typography variant="body1" paragraph>
        You may upload content including but not limited to:
        <ListComp
          arr={["Code", "Images", "Videos", "Fonts", "Other digital assets"]}
        />
        You represent that:
        <ListComp
          arr={[
            "You have all necessary rights to upload such content",
            "Your content does not violate any laws or third-party rights",
            "You are solely responsible for your content and its use",
          ]}
        />
      </Typography>
      <Typography variant="h3" sx={{ mb: "12px" }}>
        Services and pricing
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Plans and limits
      </Typography>
      <Typography variant="body1" paragraph>
        <ListComp
          arr={[
            "Free plan: As described at https://icōd.ai./pricing",
            "Paid plans: As described at https://icōd.ai./pricing",
            "Token limits reset at the start of each month",
            "We reserve the right to modify plans and pricing at any time",
          ]}
        />
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Support services
      </Typography>
      <Typography variant="body1" paragraph>
        <ListComp
          arr={[
            "Available only to Scale and Enterprise plan subscribers",
            "Contact via support@icōd.ai.",
            "Limited to platform-related issues",
            "Does not include application debugging services",
            "Subject to our capacity and availability",
          ]}
        />
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Subscription / Payment terms
      </Typography>
      <Typography variant="body1" paragraph>
        <ListComp
          arr={[
            "Our service is subscription-based with recurring monthly billing",
            "Subscriptions automatically renew at the end of each billing period",
            "No annual billing options currently available",
          ]}
        />
      </Typography>
      <Typography variant="h3" sx={{ mb: "12px" }}>
        Fulfillment and Service Policies
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Service Delivery
      </Typography>
      <Typography variant="body1" paragraph>
        <ListComp
          arr={[
            "Access to our platform is granted immediately upon successful payment processing",
            "You will receive login credentials via email",
            "If you experience any issues with access, contact support@icōd.ai.",
          ]}
        />
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Refund Policy
      </Typography>
      <Typography variant="body1" paragraph>
        <ListComp
          arr={[
            "All payments are non-refundable unless otherwise determined by us",
            "In cases where refunds are approved, they will be processed to the original payment method",
          ]}
        />
        Refunds may be considered in cases of:
        <ListComp
          arr={[
            "Billing errors",
            "Duplicate charges",
            "Other circumstances at our sole discretion",
          ]}
        />
      </Typography>
      <Typography variant="h3" sx={{ mb: "12px" }}>
        Cancellation Policy
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        How to Cancel
      </Typography>
      <Typography variant="body1" paragraph>
        You can cancel your subscription at any time by:
        <ListComp
          arr={[
            "Navigating to your Profile",
            "Selecting Plans & Billing",
            "Clicking Manage Subscription",
            "Selecting Cancel",
          ]}
        />
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Cancellation Terms
      </Typography>
      <Typography variant="body1" paragraph>
        <ListComp
          arr={[
            "Your subscription remains active until the end of the current billing period",
            "You will continue to have full access until the end of your paid period",
            "Your subscription will not automatically renew after cancellation",
            "Any unused message credits expire at the end of the subscription",
            "No partial refunds are provided for unused portions of the billing period",
          ]}
        />
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Digital Service Notice
      </Typography>
      <Typography variant="body1" paragraph>
        <ListComp
          arr={[
            "As a digital service providing access to our AI software engineering platform, traditional return policies do not apply",
            "All sales are final unless otherwise specified in these terms",
          ]}
        />
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        DMCA compliance
      </Typography>
      <Typography variant="body1" paragraph>
        We respect intellectual property rights and comply with the Digital
        Millennium Copyright Act (DMCA). For any DMCA-related issues, contact us
        at feedback@icōd.ai. with:
        <ListComp
          arr={[
            "Identification of the copyrighted work claimed to be infringed",
            "Identification of the allegedly infringing material",
            "Your contact information",
            "A statement of good faith belief in the infringement",
            "A statement of accuracy under penalty of perjury",
          ]}
        />
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Prohibited uses
      </Typography>
      <Typography variant="body1" paragraph>
        You explicitly agree not to:
        <ListComp
          arr={[
            "Reverse engineer the Services",
            "Use automated tools or bots without permission",
            "Attempt to access unauthorized areas of the Services",
            "Use the Services for illegal activities",
            "Create multiple free accounts to circumvent limits",
            "Use the Services in a way that could harm other users",
            "Upload malicious code or content",
            "Attempt to overwhelm or crash our systems",
          ]}
        />
        icōd.ai may never be used to develop anything subjected to sanctions or
        other export restrictions, or are in any other way not compliant with
        the laws of the jurisdiction that icōd.ai or the user operates in.
        icōd.ai may also never be used by in regions, or by anyone subject to
        sanctions or export restrictions.
      </Typography>
      <Typography variant="h3" sx={{ mb: "12px" }}>
        Promotions and feedback
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Promotions
      </Typography>
      <Typography variant="body1" paragraph>
        We may offer promotions, contests, or sweepstakes, which may have
        additional terms and conditions.
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        User feedback
      </Typography>
      <Typography variant="body1" paragraph>
        <ListComp
          arr={[
            "We welcome feedback and suggestions",
            "We may implement any feedback without compensation",
            "Providing feedback grants us a perpetual, worldwide license to use it",
            "No credit or compensation is due for implemented suggestions",
          ]}
        />
      </Typography>
      <Typography variant="h3" sx={{ mb: "12px" }}>
        Disclaimer of warranties
      </Typography>
      <Typography variant="body1" paragraph>
        YOUR USE OF THE SITE, SERVICES AND ALL ICŌD.AI CONTENT IS ENTIRELY AT
        YOUR OWN RISK. THE PLATFORM, SERVICES, AND ALL CONTENT ARE PROVIDED "AS
        IS" AND "AS AVAILABLE" WITHOUT ANY GUARANTEES. TO THE FULLEST EXTENT
        PERMITTED BY LAW, ICŌD.AI AND OUR SUPPLIERS AND LICENSORS EXPLICITLY
        DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT
        LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. WE MAKE NO GUARANTEES
        REGARDING THE ACCURACY, RELIABILITY, OR USEFULNESS OF THE PLATFORM,
        SERVICES, OR ANY ICŌD.AI CONTENT, AND YOUR USE OF THESE IS ENTIRELY AT
        YOUR OWN RISK. ANY MATERIALS OR CODE YOU DOWNLOAD OR ACCESS THROUGH OUR
        PLATFORM OR SERVICES IS OBTAINED AT YOUR OWN DISCRETION AND RISK, AND
        YOU WILL BE SOLELY RESPONSIBLE FOR ANY SYSTEM DAMAGE OR DATA LOSS THAT
        MAY RESULT. NO ORAL OR WRITTEN INFORMATION OR ADVICE PROVIDED BY US OR
        THROUGH OUR PLATFORM OR SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY
        STATED IN THIS AGREEMENT. CERTAIN JURISDICTIONS MAY PROHIBIT SUCH
        WARRANTY DISCLAIMERS, SO SOME OR ALL OF THESE LIMITATIONS MAY NOT APPLY
        TO YOU.
      </Typography>
      <Typography variant="h4" sx={{ fontWeight: "700", mb: "6px" }}>
        Limitation of liability
      </Typography>
      <Typography variant="body1" paragraph>
        ICŌD.AI, ALONG WITH ITS AFFILIATES, AGENTS, OFFICERS, EMPLOYEES,
        SUPPLIERS AND LICENSORS, SHALL NOT BE LIABLE FOR ANY DAMAGES, WHETHER
        DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY,
        INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOST PROFITS, GOODWILL, DATA,
        USE, OR OTHER INTANGIBLE LOSSES (EVEN IF ADVISED OF SUCH POSSIBILITY),
        ARISING FROM YOUR USE OF OR INABILITY TO USE OUR PLATFORM, SERVICES, OR
        ICŌD.AI CONTENT. UNDER NO CIRCUMSTANCES WILL ICŌD.AI BE HELD RESPONSIBLE
        FOR ANY DAMAGES OR LOSSES RESULTING FROM UNAUTHORIZED ACCESS, SECURITY
        BREACHES, OR INTERFERENCE WITH OUR PLATFORM, SERVICES, OR YOUR ACCOUNT.
      </Typography>
      <Typography variant="body1" paragraph>
        TO THE MAXIMUM EXTENT ALLOWED BY LAW, ICŌD.AI ACCEPTS NO LIABILITY OR
        RESPONSIBILITY FOR:
        <ListComp
          arr={[
            "Content errors, inaccuracies, or omissions in any form",
            "Personal injury or property damage resulting from your use of our platform or services",
            "Unauthorized access to our servers or your personal information",
            "Service interruptions, transmission failures, or platform unavailability",
            "Malicious software or code that may be transmitted through our platform",
            "Any errors, losses, or damages arising from the use of our content or services",
          ]}
        />
        AI-generated code that:
        <ListComp
          arr={[
            "Contains bugs, errors, or security vulnerabilities",
            "Fails to meet your specific requirements or expectations",
            "Does not achieve your intended business or technical goals",
            "Becomes obsolete or incompatible with other systems",
            "Causes issues when integrated with other software",
            "Results in any form of data loss or corruption",
          ]}
        />
        AI system limitations or failures, including:
        <ListComp
          arr={[
            "Incorrect or incomplete code generation",
            "Misunderstanding of your requirements or prompts",
            "Generation of non-optimal or inefficient solutions",
            "Inconsistencies in code output quality",
            "Failures to follow best practices or coding standards",
            "In the event that an application fails to generate and credits are consumed, no refunds will be issued, as such issues may originate from the underlying language model and are beyond our direct control. ",
          ]}
        />
        Platform-related issues such as:
        <ListComp
          arr={[
            "Temporary or permanent service unavailability",
            "Slow response times or performance degradation",
            "Loss of saved projects or conversation history",
            "Interface bugs or usability issues",
            "API failures or inconsistencies",
            "Authentication or authorization problems",
          ]}
        />
        Business impact resulting from:
        <ListComp
          arr={[
            "Reliance on generated code in production systems",
            "Time or resources spent modifying generated code",
            "Project delays or missed deadlines",
            "Additional development costs or technical debt",
            "Integration challenges with existing systems",
            "Customer or user dissatisfaction",
          ]}
        />
        Third-party related issues:
        <ListComp
          arr={[
            "Compatibility problems with external services",
            "Licensing issues in generated code",
            "Security vulnerabilities in recommended dependencies",
            "Changes in external APIs or services",
            "Conflicts with other development tools",
          ]}
        />
        Data-related concerns:
        <ListComp
          arr={[
            "Loss of prompt history or generated code",
            "Unintended data exposure in generated code",
            "Training data biases affecting code generation",
            "Incorrect handling of sensitive information",
            "Data privacy or compliance issues",
          ]}
        />
        THIS LIST IS NOT EXHAUSTIVE, AND ICŌD.AI'S LIMITATION OF LIABILITY
        EXTENDS TO ALL POSSIBLE ISSUES, WHETHER LISTED HERE OR NOT, ARISING FROM
        THE USE OF OUR AI-POWERED PLATFORM AND SERVICES. IN NO EVENT SHALL OUR
        TOTAL LIABILITY AND THAT OF OUR SUPPLIERS AND LICENSORS, ARISING FROM OR
        RELATING TO YOUR USE OF THE PLATFORM, SERVICES, AND ICŌD.AI CONTENT
        (INCLUDING WARRANTY CLAIMS), REGARDLESS OF THE TYPE OF CLAIM OR LEGAL
        THEORY, EXCEED THE AMOUNT YOU HAVE PAID TO US FOR THE SERVICES IN THE
        TWELVE MONTHS PRECEDING THE CLAIM. IF YOU RESIDE IN CALIFORNIA, YOU
        WAIVE CALIFORNIA CIVIL CODE §1542, WHICH STATES: A GENERAL RELEASE DOES
        NOT EXTEND TO CLAIMS THE CREDITOR OR RELEASING PARTY DOES NOT KNOW OR
        SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE
        RELEASE. SOME JURISDICTIONS DO NOT PERMIT LIABILITY LIMITATIONS FOR
        CERTAIN DAMAGES, SO THESE LIMITATIONS MAY NOT FULLY APPLY TO YOU.
      </Typography>
      <Typography variant="h3" sx={{ mb: "12px" }}>
        Indemnification
      </Typography>
      <Typography variant="body1" paragraph>
        You agree to defend, indemnify, and hold harmless icōd.ai, including our
        officers, directors, employees, agents, licensors, affiliates, and
        representatives, from and against any claims, liabilities, damages,
        judgments, awards, losses, costs, or expenses (including reasonable
        legal fees) arising from:
        <ListComp
          arr={[
            "Your use of our platform and services",
            "Breach of these Terms",
            "Infringement of third-party rights",
            "Applications, websites, or services you create using our platform",
            "Any content you submit or share through our services",
          ]}
        />
      </Typography>
      <Typography variant="h3" sx={{ mb: "12px" }}>
        Legal notices and governing law
      </Typography>
      <Typography variant="body1" paragraph>
        Our platform and services operate from our offices in Florida, United
        States. We make no claims about the appropriateness or availability of
        the services for use in other locations. Users accessing our platform
        from other jurisdictions do so voluntarily and must comply with all
        applicable local and United States laws, including export and import
        regulations. Access is prohibited from United States embargoed countries
        or by denied or restricted parties under U.S. law. These Terms shall be
        governed by Florida Law, excluding conflicts of law principles. Any
        disputes related to these Terms or your use of our platform must be
        brought exclusively in the courts of Delaware, and you consent to the
        personal jurisdiction of these courts. Our failure to exercise any right
        or provision of these Terms doesn't constitute a waiver unless we
        acknowledge it in writing. If any provision of these Terms is found
        invalid or unenforceable, the remaining provisions will continue in full
        effect.
      </Typography>
      <Typography variant="body1" paragraph>
        We reserve the right to terminate accounts AND ACCESS RIGHTS that
        violate these Terms
      </Typography>
    </DialogContent>
  );
};

export default TermAndCondation;
