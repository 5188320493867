import { Box, Typography } from "@mui/material";

const PrivacySection = () => {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(258.25deg, #5935A6 18.94%, #7C4CE1 91.06%)",
        position: "relative",
        height: "400px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      {/* Transparent boxed background image */}
      <Box
        component="img"
        src="https://icod-product-bucket.s3.amazonaws.com/media/assets/1744362824296-pravicybg.svg"
        alt="Privacy Background"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: 1,
        }}
      />

      {/* Text on top */}
      <Box sx={{ position: "relative", zIndex: 2 }}>
        <Typography
          variant="h2"
          color="white"
          sx={{
            fontWeight: "bold",
          }}
        >
          Privacy Policy
        </Typography>
        <Typography
          variant="body1"
          color="white"
          sx={{ textAlign: "center", mt: "24px" }}
        >
          Latest update: 15 April 2025
        </Typography>
      </Box>
    </Box>
  );
};

export default PrivacySection;
