import { Box, Typography } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import chevron_down from "@/assets/images/chevron_down.svg";
import NoTransaction from "@/assets/images/no_transaction.svg";





function createData(invoiceId, billingDate, plan, amount, status) {
    return { invoiceId, billingDate, plan, amount, status };
}

const rows = [
    createData('#23456', '23 Jan 2023', 'Basic Plan', '$1200', 'Paid'),
    createData('#23456', '23 Jan 2023', 'Basic Plan', '$1200', 'Paid'),
    createData('#23456', '23 Jan 2023', 'Basic Plan', '$1200', 'Paid'),
    createData('#23456', '23 Jan 2023', 'Basic Plan', '$1200', 'Paid'),
    createData('#23456', '23 Jan 2023', 'Basic Plan', '$1200', 'Paid'),
];



const BillingHistory = (props) => {
    const { t, ProfileData } = props;
    return (
        <Box sx={BoxStyle}>
            <Typography variant="h6">
                {t("Dashboard.Billing_History")}
            </Typography>
            {ProfileData?.billing_history ? (
                <Table >
                    <TableHead>
                        <TableRow>
                            <TableCell >
                                <Box sx={TableHeaderStyle}>
                                    <Typography variant="subtitle1">
                                        {t("Dashboard.Invoice_ID")}
                                    </Typography>
                                    <img
                                        src={chevron_down}
                                        style={{ width: "7px", height: "6px" }}
                                    />
                                </Box>

                            </TableCell>
                            <TableCell >
                                <Box sx={TableHeaderStyle}>
                                    <Typography variant="subtitle1">
                                        {t("Dashboard.Billing_Date")}
                                    </Typography>
                                    <img
                                        src={chevron_down}
                                        style={{ width: "7px", height: "6px" }}
                                    />
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={TableHeaderStyle}>
                                    <Typography variant="subtitle1">
                                        {t("Dashboard.Plan")}
                                    </Typography>
                                    <img
                                        src={chevron_down}
                                        style={{ width: "7px", height: "6px" }}
                                    />
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={TableHeaderStyle}>
                                    <Typography variant="subtitle1">
                                        {t("Dashboard.Amount")}
                                    </Typography>
                                    <img
                                        src={chevron_down}
                                        style={{ width: "7px", height: "6px" }}
                                    />
                                </Box>
                            </TableCell>
                            <TableCell >
                                <Box sx={TableHeaderStyle}>
                                    <Typography variant="subtitle1">
                                        {t("Dashboard.Status")}
                                    </Typography>
                                    <img
                                        src={chevron_down}
                                        style={{ width: "7px", height: "6px" }}
                                    />
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow >
                                <TableCell > <Typography sx={BodyTextStyle} variant="subtitle1">{row.invoiceId}</Typography></TableCell>
                                <TableCell ><Typography sx={BodyTextStyle} variant="subtitle1">{row.billingDate}</Typography></TableCell>
                                <TableCell ><Typography sx={BodyTextStyle} variant="subtitle1">{row.plan}</Typography></TableCell>
                                <TableCell ><Typography sx={BodyTextStyle} variant="subtitle1">{row.amount}</Typography></TableCell>
                                <TableCell ><Typography sx={BodyStatusTextStyle} variant="subtitle1">{row.status}</Typography></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <Box sx={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "center" }}>
                    <img
                        src={NoTransaction}
                        style={{ width: "180px", height: "180px" }}
                    />
                    <Typography variant='subtitle2'>
                        No recent transactions found.
                    </Typography>

                </Box>
            )}


        </Box >
    )
}

export default BillingHistory;

const BoxStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "22px",
}
const BodyTextStyle = {
    fontWeight: "400"
}

const BodyStatusTextStyle = {
    fontWeight: "400",
    borderRadius: "33px",
    width: "fit-content",
    backgroundColor: "#E1FFDC",
    color: "#07A104",
    padding: "4px 24px"
}

const TableHeaderStyle = {
    display: "flex",
    gap: "8px",
    alignItems: "center",
}
