import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import {
  deleteUserDetails,
  getUserDetails,
  updateTour,
  updateUserDetails,
} from "../thunk/userThunk";

function handlePending(state: UserState) {
  state.loading = true;
  state.error = null;
}

function handleRejected(state: UserState, action: any) {
  state.loading = false;
  state.error = action.payload;
}

export interface UserState {
  user: any;
  loading: boolean;
  error: string | null;
  generalLoader: boolean;
  productionModel: boolean;
  prototypeModel: boolean;
  guestAuthModel: boolean;
  tour: boolean;
}

const initialState: UserState = {
  user: null,
  loading: false,
  error: null,
  generalLoader: false,
  productionModel: false,
  prototypeModel: false,
  guestAuthModel: false,
  tour: true,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setGeneralLoader: (state, action) => {
      state.generalLoader = action.payload;
    },
    setUserData: (state, action) => {
      state.user = action.payload;
    },
    setProductionModel: (state, action) => {
      state.productionModel = action.payload;
    },
    setPrototypeModel: (state, action) => {
      state.prototypeModel = action.payload;
    },

    setGuestAuthModel: (state, action) => {
      state.guestAuthModel = action.payload;
    },
    setClearState: (state) => {
      state.productionModel = false;
      state.prototypeModel = false;
      state.user = null;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<UserState>) => {
    builder.addCase(getUserDetails.pending, handlePending);
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action?.payload.user;
      state.tour = action?.payload.user.tour;
    });
    builder.addCase(getUserDetails.rejected, handleRejected);
    // update
    builder.addCase(updateUserDetails.pending, handlePending);
    builder.addCase(updateUserDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action?.payload.user;
    });
    builder.addCase(updateUserDetails.rejected, handleRejected);
    // delete
    builder.addCase(deleteUserDetails.pending, handlePending);
    builder.addCase(deleteUserDetails.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteUserDetails.rejected, handleRejected);
    // update tour
    builder.addCase(updateTour.pending, handlePending);
    builder.addCase(updateTour.fulfilled, (state) => {
      state.tour = true;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateTour.rejected, handleRejected);
  },
});

export const {
  setGeneralLoader,
  setProductionModel,
  setPrototypeModel,
  setUserData,
  setGuestAuthModel,
  setClearState,
} = userSlice.actions;
export default userSlice.reducer;
