import TermAndCondation from "../auth/signup/TermAndCondation";
import MainNavbar from "@/layout/navbar/mainNavbar";
import { Box, useTheme } from "@mui/material";
import CustomFooter from "../home/components/UpdatedFooter";
import PrivacySection from "./components/Banner";

const PrivicyPolicy = () => {
  const theme = useTheme();

  return (
    <Box sx={BoxStyle(theme)}>
      <Box sx={NavbarBoxStyle}>
        <MainNavbar />
      </Box>
      <Box sx={BodyBoxStyle}>
        {" "}
        <Box
          sx={{
            overflow: "hidden",
          }}
        >
          <Box id="icod-scroll-to-top-from-footer">
            <PrivacySection />
            <Box
              sx={{ mx: { xs: "1rem", sm: "3rem", md: "6rem", lg: "7rem" } }}
            >
              <TermAndCondation />
            </Box>
          </Box>
          <CustomFooter />
        </Box>
      </Box>
    </Box>
  );
};

export default PrivicyPolicy;
const NavbarBoxStyle = {
  position: "sticky",
  top: 0,
  zIndex: 1100,
  height: "72px",
  mx: { xs: "1rem", sm: "3rem", md: "6rem", lg: "7rem" },
};
export const BoxStyle = (theme) => ({
  width: "100%",
  height: "100vh",
  maxWidth: "1920px",
  mx: "auto",
  bgcolor: theme?.palette?.background?.paper,
});
const BodyBoxStyle = {
  height: "calc(100vh - 72px)",
  width: "100%",
  overflow: "auto",
};
